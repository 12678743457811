<template>
   <div class="flex flex-col lg:flex-row lg:space-x-4">

    <!-- SideBar -->
    <vs-card class="flex-1 px-1" id="testID">

      <vs-button 
        color="dark" 
        type="flat"
        icon="arrow_back_ios"
        @click="goToInsights"
        class="p-0 lg:py-1 text-sm">
        Back to Insights
      </vs-button>

      <div class="mt-8 lg:mt-6">
        <p class="text-green-dark text-lg font-bold mb-4"> Appointments </p>
        <div class="flex flex-col space-y-2">
          <p 
            @click="showBrand('single_vs_multiple_treatment')"
            class="listHover px-4 py-2 cursor-pointer font-medium text-base"
            :class="sideMenuOptions.single_vs_multiple_treatment ? 'categoryActive' : 'bg-gray-100 rounded-lg'"> 
            Number of Single VS Multiple Treatments </p>
          <p 
            @click="showBrand('appointment_notes')"
            class="listHover px-4 py-2 cursor-pointer font-medium text-base"
            :class="sideMenuOptions.appointment_notes ? 'categoryActive' : 'bg-gray-100 rounded-lg'">
          Appointment Notes VS Missing Notes
          </p>
        </div>
      </div>

    </vs-card>
    <!-- End SideBar -->

    <div class="w-full lg:w-9/12">
      <AppointmentNotes v-if="sideMenuOptions.appointment_notes"></AppointmentNotes>
      <SingleVSMultiple v-else></SingleVSMultiple>
      <!-- <vs-table
        max-items="10" pagination
        :data="table.data"
        noDataText="No Data Available"
        :hoverFlat="true"
        v-if="sideMenuOptions.appointment_notes"
        :key="'main-'+tableKey"
      >
        <template slot="thead">
          <vs-th v-for="(th, indexth) in table.headers" :key="indexth">{{
            th
          }}</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in table.data">
            <vs-td v-for="(value, name) in data[indextr]" :key="name">
              {{ value }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table> -->
   
    </div>
  </div>
</template>

<script>
import SingleVSMultiple from './SingleVSMultiple.vue';
import AppointmentNotes from './AppointmentNotes.vue';
import { mapState, mapActions } from "vuex";

export default {
  components: {
    SingleVSMultiple,
    AppointmentNotes
  },
  data() {
    return {
      sideMenuOptions: {
        appointment: true,
        single_vs_mulitiple_treatment: true,
        appointment_notes: false
      },
      urlType: "",
      userbrands: "",
      tableKey: "",
    }
  },
  watch: {
    table: {
      handler() {
        const randNumber = Math.random().toString(16).slice(2)
        this.tableKey = randNumber
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("analytics", ["fetchAnalyticsFilters"]),
    goToInsights() {
      this.$router.push({ name: `${this.urlType}Insights` });
    },
    goToPage(value){
      if(value){
        this.$router.push({name: value})
      }
    },
    showBrand(value) {
      this.sideMenuOptions[value] = !this.sideMenuOptions[value]
      if (this.sideMenuOptions[value]) {
        Object.keys(this.sideMenuOptions).map((key) => {
          if (key !== 'appointment' && key !== value) {
            this.sideMenuOptions[key] = false;
          }
        })
      }
    },
    getFilters() {
      let payload = {}
      const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)

      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) {
        payload.org = [org_id];
      }
      if(this.urlType === "Supplier" && this.userbrands) payload.brand_id = this.userbrands
      this.$vs.loading();
      this.fetchAnalyticsFilters(payload).then(() => this.$vs.loading.close()
      ).catch((err) => {
        console.error("##ERROR: ", err.message)
        this.$vs.loading.close();
      });
    },
  },
  computed: {
    ...mapState("analytics", ["table"]),
  },
  created() {
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
      this.userbrands = userType.brands
    }else{
      this.urlType = "OrgOwner";
    }
    this.getFilters();
  },
  destroyed() {
    this.hideMarkerWidget();
  }
};
</script>

<style>
  .insightsTabs .vs-tabs--ul {
    overflow: auto
  } 
  .insightsTabs .vs-tabs--li button.vs-tabs--btn {
    padding: .5rem 1rem;
  }
  .listHover:hover {
    background-color: rgba(111, 214, 198, 0.6);
    color: #3E695C;
    border-radius: 0.5em;
  }
  .categoryActive {
    background-color: #074230;
    color: white;
    border-radius: 0.5em;
  }
  .categoryActive span {
    color: white !important;
  }
</style>