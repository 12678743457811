<template>
  <vs-card>
    <div slot="header">

      <h5 class="text-green-dark font-bold my-3 mx-2">
        Single VS Multiple Treatment
      </h5>

      <vs-tabs v-model="active" class="insightsTabs">
        <vs-tab label="By Clinic">
          <SingleVSMultiple></SingleVSMultiple>
        </vs-tab>
        <vs-tab label="By Nurse">
          <SingleVSMultiple isByNurse></SingleVSMultiple>
        </vs-tab>
      </vs-tabs>

      <!-- <div class="flex justify-end mt-2 mb-2 w-full">
        <vs-button class="w-full lg:w-auto" @click="handleExportToCSV">
          Export
        </vs-button>
      </div> -->

      <vs-table
        max-items="10"
        pagination
        noDataText="No Data Available"
        :data="table.data"
        :hoverFlat="true"
        :key="'main-' + tableKey"
        class="customtable w-full"
      >
          <template slot="thead">
            <vs-th v-for="(th, indexth) in table.headers" :key="`th-${indexth}`">
              <!-- <span v-if="indexth === 0" class="capitalize">{{ "Clinic" }}</span> -->
              {{ th }}
            </vs-th>

          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="`tr-${indextr}`" v-for="(tr, indextr) in table.data">
              <vs-td data-label="{ th }" v-for="(value, name) in data[indextr]" :key="name">
                {{ value }}
              </vs-td>
            </vs-tr>
          </template>
      </vs-table>

    </div>
  </vs-card>
</template>

<script>
import SingleVSMultiple from "../../components/insights/treatments/SingleVSMultiple.vue";
import { mapState } from "vuex";
import utils from "@/assets/utils";
import moment from "moment";

export default {
  components: {
    SingleVSMultiple,
  },
  props: {},
  data() {
    return {
      active: 0,
      tableKey: "",
    };
  },
  computed: {
    ...mapState("analytics", ["table"]),
  },
  watch: {
    table: {
      handler() {
        const randNumber = Math.random().toString(16).slice(2);
        this.tableKey = randNumber;
      },
      deep: true,
    },
  },
  methods: {
    handleExportToCSV() {
      let csv = "\ufeff";
      let filename = "single_vs_multiple_treatment_";
      csv += this.table.headers.join(",") + "\n";

      this.table.data.forEach((row) => {  
        csv += `${row.category},${row.single},${row.multiple},${row.total}\n`;
      });

      utils.exportToCSV(csv, `${filename}${moment().format("YYMMDDHHmm")}`);
    },
  },
};
</script>

<style>
  .customtable .vs-table--thead th {
    background: #f3faf8;
  }
  /* Responsive <table> */
  @media screen and (max-width: 600px) {
    .customtable .vs-table--content .vs-con-table .vs-con-tbody {
        border: none;
    }
    .customtable .vs-table--tbody-table {
        min-width: unset;
    }
    .customtable .vs-table--header {
      flex-direction: column;
    }
    .customtable .vs-table--thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none !important;
    }
    .customtable .vs-table--tr {
      display: block;
      margin-bottom: .625em;
    }
    .customtable .vs-table--td {
      border-bottom: .05rem solid #ddd;
      display: block;
      font-size: 1rem;
      text-align: right;
    }
    .customtable .vs-table--td:first-child{
      min-width: 230px;
    }
    .customtable .vs-table--td::before {
      content: attr(data-label);
      float: left;
      font-weight: 600;
    }
    .customtable .vs-table--td:last-child {
      border-bottom: 0;
    }
  }
</style>
