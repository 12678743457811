<template>
  <vs-row vs-w="12">
      <GlobalFilters 
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        @updateFilters="selected = $event"
        :page="'single-vs-multiple-treatment'"
        :urlType="urlType"
      />
      
      <!-- Chart -->
      <div class="flex flex-col md:flex-row items-center justify-end space-y-4 md:space-y-0 w-full my-4">
        <p v-if="allTotalCount" class="optionslabel w-full md:w-1/2 text-center md:text-right">Total count of treatments: {{ Number.parseFloat(allTotalCount).toFixed(0) }}</p>
      </div>

      <BarChart 
        :chartData="chartData"
        :chartOptions="chartOptions"
        
      />

    </vs-row>
</template>

<script>
import BarChart from '../charts/BarChart.vue'
import GlobalFilters from "../common/GlobalFilters"
import {
  filterCategories
} from "../common/GlobalFilters/filterConstant.js"
import { mapActions, mapMutations, mapState } from "vuex";
import utils from '@/assets/utils'
import helper from "../../../superAdmin/insights/helper";

export default {
  components: {
    BarChart,
    GlobalFilters
  },
  props: {
    tabs: Array,
    isByNurse: Boolean
  },
  computed: {
    ...mapState('analytics', ['chartData', 'FILTERS_SELECTED']),
    allTotalCount(){      
      let count = 0;
      const chartDataSets = this.chartData.datasets ? this.chartData.datasets[2] : null
      if(chartDataSets) {
        chartDataSets.data.forEach(val => {
          count += val;
        });
      }

      return count
    }
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.NURSE,
        filterCategories.PATIENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      selected: {},
      static: {
        temp_brand: [],
      },
      // Chart data
      // chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              boxWidth: 12
            }
          },
          title: {
            display: true,
            text: ''
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'TREATMENTS DONE'
            },
            min: 0,
          },
          x: {
            title: {
              display: true,
              text: this.isByNurse ? 'Nurse' : 'Clinic'
            },
            grid : {
                display : false
            },
            ticks: {
               font: {
                size: 10
              },
              maxRotation: 90,
              minRotation: 90,
            }
          }
        }
      },
      urlType: "",
      orgId: "",
    }
  },
  methods:{
    ...mapActions("analytics", ["fetchSingleVsMultipleTreatment"]),
    ...mapMutations("analytics", ["MUTATE_CHART_DATA", "MUTATE_TABLE_DATA", "MUTATE_TABLE_HEADERS"]),
    fetchSingleVSMultipleTreatmentChartData() {

      let payload = helper.generateAnalyticsAPIParameters(this.selected)
      payload.is_nurse = this.isByNurse;
      
      if(this.selected.brand && this.selected.brand.length && this.static.temp_brand[0] === 0){
        payload.brand = this.selected.brand
      } else {
        payload.brand = this.static.temp_brand
      }

      if (["OrgOwner", "SuperAdmin"].includes(this.urlType)){
        // payload.brand = []
        payload.brand = this.selected.brand
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org: [this.orgId],
        }
      }
      this.fetchSingleVsMultipleTreatment(payload).then((res) => {
        this.MUTATE_CHART_DATA(res.data.chartData)

        const { labels, datasets } = res.data.chartData;
        const tableData = labels.map((label, index) => {
          return {
            category: label,
            single: datasets[0].data[index],
            multiple: datasets[1].data[index],
            total: datasets[2].data[index],
          }
        })
        this.MUTATE_TABLE_DATA(tableData)
      });
    },
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED)
    this.MUTATE_TABLE_DATA([])
    this.MUTATE_CHART_DATA({})
    this.MUTATE_TABLE_HEADERS([])
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.static.temp_brand = userType.brands;
    } else if(this.urlType == "Supplier" || this.urlType == "OrgOwner"){
      this.selected.brand = []
      this.static.temp_brand[0] = 0
    }
    this.MUTATE_TABLE_HEADERS([this.isByNurse ? "Nurse" : "Clinic", "Single", "Multiple", "Total Treatments"])
    // this.fetchSingleVSMultipleTreatmentChartData()
  },
  watch: {
    selected: {
      handler() {
        this.fetchSingleVSMultipleTreatmentChartData();
      },
      deep: true,
    }
  },
}
</script>

<style ></style>